import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Avatar, Button } from '@mui/material/';
import InputSelectMultiple from './components/_basic_filters/InputSelectMultiple';
import { TAG_OPTIONS } from './const/ref';
import { filterTags } from './utils/filter_tags';
import { usePermission } from '../context/permission';
import { IfAnyGranted } from 'react-authorization';
import { useGlobal } from '../context/global';
import { useReferences } from '../context/references';

export default () => {
  const { storeSelectedFirm } = useGlobal();
  const { atleastAngel, getMyRoles, isAngel } = usePermission();
  const { basic_organizations } = useReferences();
  const [selectedTags, setSelectedTags] = useState(['OEO']);
  const [displayedFirms, setDisplayedFirms] = useState([]);

  const onChangeTag = (selectedValues) => {
    setSelectedTags(selectedValues.tags);
  };

  const loadingOrganization = () => {
    if (basic_organizations.length === 0) {
      return <div>loading firms...</div>;
    } else {
      return null;
    }
  };

  const noResults = () => {
    const noResultMessage =
      'There are no firms matching the selected tag(s)...';
    const noTagsMessage = 'Please select a tag from the dropdown...';

    const noResultsReturned =
      displayedFirms.length === 0 && basic_organizations.length > 0;
    const noTagsSelected =
      selectedTags === 'undefined' || selectedTags.length === 0;
    const message = noTagsSelected ? noTagsMessage : noResultMessage;

    if (noResultsReturned) {
      return <div>{message}</div>;
    } else {
      return null;
    }
  };

  const firmsToDisplay = (selectedTags, basic_organizations) => {
    return isAngel() ? filterTags(selectedTags, basic_organizations) : basic_organizations;
  };

  const handleIconClick = (firm) => {
    storeSelectedFirm(firm);
  };

  useEffect(() => {
    const firms = firmsToDisplay(selectedTags, basic_organizations);
    setDisplayedFirms(firms);
  }, [selectedTags, basic_organizations]);

  return (
    <div>
      <h2>Welcome to Frittata</h2>
      <IfAnyGranted expected={atleastAngel()} actual={getMyRoles()}>
        <InputSelectMultiple
          width={215}
          name="tags"
          title="Tags"
          options={TAG_OPTIONS}
          onChange={onChangeTag}
          default_value={['OEO']}
        />
      </IfAnyGranted>
      <div
        style={{
          justifyContent: 'space-evenly',
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {displayedFirms.length > 0
          ? displayedFirms.map((firm) => (
              <Link
                href={`/app/firm/${firm.id}`}
                color="inherit"
                key={`menu_firm_${firm.id}`}
                onClick={() => handleIconClick(firm)}
              >
                <Button variant={'outlined'} style={{ margin: '20px' }}>
                  <Avatar
                    key={`org_avatar_${Math.random()}`}
                    alt={firm.name}
                    src={firm.avatar}
                    style={{ marginRight: '10px' }}
                  >
                    {firm.code}
                  </Avatar>
                  {firm.name}
                </Button>
              </Link>
            ))
          : null}
        {loadingOrganization()}
        {noResults()}
      </div>
    </div>
  );
};
