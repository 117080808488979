import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Pagination, PaginationItem } from "@mui/material";
import {
  TextField,
  Box,
  Button,
  Stack,
  CardContent,
  Card,
  CardHeader,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";

import { doAuthenticatedGet } from "../../../actions/_methods";
import { API_PATH, USER_DATA_REPORTS } from "../../../const/api_paths";
import InputPeople from "../../board/components/_basic_filters/InputPeople";
import { useReferences } from "../../context/references";

import Organizational from "./components/_Organizational";
import Results from "./components/_Results";
import TaskCategory from "./components/_TaskCategory";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: "10px",
    border: "1px solid #9f9f9f",
  },
  buttons: {
    marginTop: "20px",
    marginBottom: "20px",
    justifyContent: "flex-end",
  },
}));

export default () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    connected_users: user_options,
    organizations,
    loadConnectedUsers,
    loadOrganizations,
    loadTaskCategories,
  } = useReferences();

  const defaultFilters = {
    start_date: moment().startOf("week").format("YYYY-MM-DD"),
    end_date: moment().endOf("week").format("YYYY-MM-DD"),
  };
  const [results, setResults] = useState([]);
  const [date_range_str, setDateRangeStr] = useState("");
  const [filters, setFilters] = useState(defaultFilters);
  const [loading, setLoading] = useState(false);
  const [showInitialMessage, setShowInitialMessage] = useState(true);
  const [page, setPage] = useState(1);
  const [total_pages, setTotalPages] = useState(1);
  const [disableGenerateReport, setDisableGenerateReport] = useState(false);

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    loadConnectedUsers();
    loadOrganizations();
    loadTaskCategories();

    // Load the initial data
    setFilters({ ...filters, timezone: tz });
    // handleFilter(); Save bandwidth by not loading the first time
  }, []);

  const doFilter = (page) => {
    setLoading(true);
    setShowInitialMessage(false);
    var target_url = `${USER_DATA_REPORTS}?page=${page}&${dataToParams()}`;
    doAuthenticatedGet(
      target_url,
      {},
      {
        success: onSuccess,
        general: onSuccess,
        error: onError,
      }
    );
  };

  const handleFilter = () => {
    setPage(1);
    doFilter(1);
  };

  const onSuccess = (res) => {
    setDisableGenerateReport(false);
    setLoading(false);
    setResults(res["rows"]);
    setDateRangeStr(res["date_range_readable"]);
    setTotalPages(res["total_pages"]);
  };

  const onError = (res) => {
    let errorMessage = "Unable to fetch the report:";
    if (res.errors && Array.isArray(res.errors)) {
      errorMessage += " " + res.errors.join(" ");
    }
    enqueueSnackbar(errorMessage, {
      variant: "error",
    });
    setDisableGenerateReport(true);
    setLoading(false);
  };

  const onPaginate = (event, value) => {
    setPage(value);
    doFilter(value);
  };

  const handleGenerateReport = () => {
    console.log("Generate Report", filters);

    const target_url = API_PATH(
      `/reports/user_data.csv?page=${page}&${dataToParams()}`
    );
    console.log(target_url);
    window.location = target_url;
  };

  const dataToParams = () => {
    const new_data = { ...filters };
    Object.keys(new_data).forEach((key) => {
      if (new_data[key] === null) {
        delete new_data[key];
      }
    });

    return new URLSearchParams(new_data).toString();
  };

  const handleChangeStartDate = (event) => {
    console.log(event.target.value);
    setFilters({ ...filters, start_date: event.target.value });
  };

  const handleChangeEndDate = (event) => {
    console.log(event.target.value);
    setFilters({ ...filters, end_date: event.target.value });
  };

  const handleChange = (new_data) => {
    setFilters({ ...filters, ...new_data });
  };

  const onChangeOrganization = (new_data) => {
    setFilters({ ...filters, ...new_data });
  };

  const onChangeTaskCategory = (new_data) => {
    setFilters({ ...filters, ...new_data });
  };

  return (
    <Card className={classes.card}>
      <CardHeader title="User Weekly Report v2" />
      <CardContent>
        <Box>
          <Stack direction="row" spacing={2}>
            <InputPeople
              name="user_id"
              title="Person"
              options={user_options}
              onChange={handleChange}
            />
            <Box display="inline-flex" justifyContent="space-between">
              <Organizational
                onChange={onChangeOrganization}
                options={organizations}
              />
            </Box>
          </Stack>
          <Stack direction="row" spacing={2} mt={2}>
            <TaskCategory onChange={onChangeTaskCategory} />
          </Stack>
          <Stack direction="row" spacing={2} mt={2}>
            <TextField
              label="Start Date"
              type="date"
              sx={{ width: "16%" }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              onChange={handleChangeStartDate}
              value={filters.start_date}
            />
            <TextField
              label="End Date"
              type="date"
              sx={{ width: "16%" }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              onChange={handleChangeEndDate}
              value={filters.end_date}
            />
          </Stack>
          <Stack direction="row" spacing={2} className={classes.buttons}>
            <Button variant="contained" color="primary" onClick={handleFilter}>
              Filter
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGenerateReport}
              disabled={disableGenerateReport}
            >
              Download Report
            </Button>
          </Stack>
        </Box>
        {loading && <Stack mt={2}>Loading... </Stack>}
        {!loading && (
          <Results
            results={results}
            date_range_str={date_range_str}
            showInitialMessage={showInitialMessage}
          />
        )}
        <Stack mt={2}>
          <Pagination
            count={total_pages}
            page={page}
            onChange={onPaginate}
            variant="outlined"
            shape="rounded"
            siblingCount={0}
            boundaryCount={1}
            renderItem={(item) => {
              // Hide the last page item
              if (item.type === "page" && item.page === total_pages) {
                return null;
              }
              return <PaginationItem {...item} />;
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
