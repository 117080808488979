export const STATUS_OPTIONS = [
  { value: 'unstarted', label: 'Unstarted' },
  { value: 'on_going', label: 'On-Going' },
  { value: 'paused', label: 'Paused' },
  { value: 'stucked', label: 'Stucked' },
  { value: 'completed', label: 'Completed' },
];

export const BILLABLE_OPTIONS = [
  { value: 'true', label: 'Billable' },
  { value: 'false', label: 'Non-billable' },
];

export const WEEK_OPTIONS = [
  { value: '1_3', label: 'First/Third Week of the month' },
  { value: '2_4', label: 'Second/Fourth Week of the month' },
];

export const TEAM_OPTIONS = [
  { value: 'Asia/Manila', label: 'Manila' },
  { value: 'America/Bogota', label: 'Bogota' },
];

export const TAG_OPTIONS = [
  { value: 'Data', label: 'Data' },
  { value: 'OEO', label: 'OEO' },
  { value: 'AP', label: 'AP' },
  { value: 'Business Support', label: 'Business Support' },
  { value: 'PM', label: 'PM' },
  { value: 'Tech', label: 'Tech' },
  { value: 'Others', label: 'Others' },
];
